export default function () {
  // eslint-disable-next-line prefer-const
  let didna = window.didna || { cmd: [] }

  const adsAreActive = () => {
    return !!parseInt(process.env.QENV.DIDNA_ADS)
  }

  const injectAdToDataResults = (arr, token, n) => {
    // Insert the <add> every <n> elements.
    let idx = n

    while (idx <= arr.length) {
      const adInfo = {
        id: token,
        index: idx
      }

      arr.splice(idx, 0, adInfo)
      idx += n + 1
    }

    return arr
  }

  const pushToAdUnits = (ads) => {
    didna.cmd.push(async () => {
      console.log('PUSH TO AD UNITS')
      await didna.removeAdUnits(ads)

      didna.createAdUnits(ads)
    })
  }

  const refreshAdUnits = () => {
    didna.cmd.push(() => {
      console.log('REFRESH AD UNITS')
      didna.ajaxPageChange()
    })
  }

  return {
    adsAreActive,
    injectAdToDataResults,
    pushToAdUnits,
    refreshAdUnits
  }
}
